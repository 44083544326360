<template>
  <ion-app>
    <ion-loading :is-open="mainStoreInstance.$state.loading.show" :message="mainStoreInstance.$state.loading.message"> </ion-loading>
  
    <ion-menu content-id="main-content">
      <ion-header>
        <ion-toolbar>
          <ion-title>Menu</ion-title>
        </ion-toolbar>
      </ion-header>
      <ion-content class="ion-padding">
        <ion-list>
          <ion-item 
                    button 
                    @click="openSesiones" 
                    v-if="userAuth().hasRole('coordinador de actividad') || userAuth().hasRole('profesor')"
                    >
            Clases
          </ion-item>

          <ion-item button @click="openEventos">
            Eventos
          </ion-item>

          <ion-item button @click="openAlquileres">
            Alquileres
          </ion-item>

          <ion-item 
                      button 
                      @click="openWhatsappLauncher"
                      v-if="userAuth().can('Buscar alumnos para Whatsapp Launcher')"
                      >
            Lanzador de Whatsapps
          </ion-item>
        </ion-list>
        <div style="text-align: center;">
          <ion-note style="font-size: smaller;">
            Versión {{ require('../package.json').version }}
          </ion-note>
          <ion-note style="display:block">Hola, {{ tools().getShortName( currentUserStore().user?.name) + '' }} </ion-note>
        </div>
      </ion-content>
    </ion-menu>
    
    <ion-router-outlet id="main-content" />
  </ion-app>
</template>

<script lang="ts" setup>
import { mainStore } from '@/stores/mainStore';
import { IonApp, IonLoading, IonMenu, IonHeader, IonToolbar, IonTitle, IonContent, IonNote, IonRouterOutlet, IonItem, IonList, menuController } from '@ionic/vue';
import tools from '@/composables/tools';
import router from '@/router';
import { currentUserStore } from '@/stores/currentUser'
import userAuth from './composables/userAuth';

const mainStoreInstance = mainStore();

const closeMenu = async() => {
      await menuController.toggle();
}

const openSesiones = () => {
  tools().setLog('Abre lista de sesiones desde el menú');
  router.push({ name: 'Sesiones'})
  closeMenu();
};

const openAlquileres = () => {
  tools().setLog('Abre la sección de alquileres desde el menú');
  location.href = '/alquileres'; //me da error si no lo hago asi.. .tengo q solucionarlo
};

const openEventos = () => {
  tools().setLog('Abre lista de eventos desde el menú');
  location.href = '/eventos'; //me da error si no lo hago asi.. .tengo q solucionarlo
  //router.push({ name: 'Eventos' });
  closeMenu();
};

const openWhatsappLauncher = () => {
  tools().setLog('Abre el whatsapp launcher');
  location.href = '/whatsappLauncher'; //me da error si no lo hago asi.. .tengo q solucionarlo
  //router.push({ name: 'Whatsapp Launcher' });
  closeMenu();
};
</script>
