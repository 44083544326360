// stores/currentUser.js

import { defineStore } from 'pinia';
import { User } from '@/interfaces/User';
import { Asistencia } from '@/interfaces/Asistencia';
import { Asiento } from '@/interfaces/Asiento';
import { Clase } from '@/interfaces/Clase';
import { ProductCart } from '@/interfaces/ProductCart';
import { ref } from 'vue';
import userAuth from '@/composables/userAuth';


export const currentUserStore = defineStore('currentUser', {
  state: () => ({
    user: null as User | null,
    asistencias: [] as Asistencia[],
    redirectAfterLogin: null as string | null
  }),

  actions:{
    update(myUser : User)
    {
        this.user = myUser;
    }
  }
});