// stores/coordinadores.ts

import { defineStore } from 'pinia';
import { Coordinador } from '@/interfaces/Coordinador';
import tools from '@/composables/tools';
import axios from 'axios';

export const useCoordinadorStore = defineStore('coordinadores', {
  state: () => ({
    coordinadores: [] as Coordinador[],
  }),

  getters: {
     // Getter para obtener la imagen principal de un coordinador por su id
     getImageById: (state) => (id: number,imageType: 'thumb'|'icon'|'hd') => {
      const coordinador = state.coordinadores.find(coordinador => coordinador.id === id);
      return coordinador ? tools().getImageClase(coordinador.mainImg + '_' + imageType + '.jpg') : null;
    }, 

    getById: (state) => (id: number): Coordinador | null => {
      const coordinador = state.coordinadores.find(coordinador => coordinador.id === id);
      return coordinador !== undefined ? coordinador : null;
    } 
  },

  actions: {
    //trae los datos en remoto de los coordinadores para volver a cargarlos en local
    async updateCoordinadores() {
      try {
        const response = await axios.get('galidancia/coordinadores');
        this.coordinadores = response.data;
      } catch (error) {
        tools().toast('No se pudieron cargar los coordinadores', 'danger', 5000);
        tools().setLog('Error al cargar los coordinadores', 'error');
      }
    },

  }
});