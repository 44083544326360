<template>
            <ion-menu content-id="main-content">
                        <ion-header>
                        <ion-toolbar>
                            <ion-title>Menú</ion-title>
                        </ion-toolbar>
                        </ion-header>
                        <ion-content class="ion-padding">
                        <ion-menu-toggle>
                          <ion-item href="tabs/mensajes">
                            <ion-label>Mensajes</ion-label>
                          </ion-item>
                          <ion-item href="organizacion/tasks">
                            <ion-label>Tareas</ion-label>
                          </ion-item>
                          <ion-item href="organizacion/procesos">
                            <ion-label>Procesos</ion-label>
                          </ion-item>
                          <ion-item href="tabs/galidancia">
                            <ion-label>Gaalidancia</ion-label>
                          </ion-item>
                          <ion-item @click="prueba()">
                            <ion-label>Prueba sesion</ion-label>
                          </ion-item>
                        </ion-menu-toggle>
                      </ion-content>

                      <ion-label class="ion-text-center" style="margin-bottom: 1em; font-size: 0.9em;">
                        Hola, {{ currentUserStore().$state.user?.name.split(' ')[0] }}
                        <ion-icon :src="logOutOutline" @click="userAuth().logout"></ion-icon>
                      </ion-label>
                    </ion-menu>

  </template>
  
  <script lang="ts">
    import { IonItem, IonLabel,IonTitle,IonToolbar,IonHeader,IonMenuToggle,IonContent,IonMenu,IonIcon } from '@ionic/vue';
    import { defineComponent } from 'vue';
    import { currentUserStore } from '@/stores/currentUser'
    import userAuth from '@/composables/userAuth';
    import { logOutOutline } from 'ionicons/icons';

    export default defineComponent({
      components: { IonItem, IonLabel,IonTitle,IonToolbar,IonHeader,IonMenuToggle,IonContent,IonMenu,IonIcon},

      setup() {
        const currentUser = currentUserStore()
        const Auth = userAuth()

        return {
          currentUser,
          Auth,
          logOutOutline,
          userAuth,
          currentUserStore
        }
      },
    });
  </script>