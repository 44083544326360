// stores/currentUser.js

import { defineStore } from 'pinia';
import { Sesion } from '@/interfaces/Sesion';
import { SesionService } from '@/services/SesionService';
import moment from 'moment';
import { Asistencia } from '@/interfaces/Asistencia';
import userAuth from '@/composables/userAuth';
import { currentUserStore } from './currentUser';
import { SesionLog } from '@/interfaces/SesionLog';

export const useSesionStore = defineStore('sesiones', {
  state: () => ({
    sesiones: [] as Sesion[],
    mySesiones: [] as Sesion[],
    mySesion: {} as Sesion, //sesion activa en la pantalla que esta viendo el usuario
    mySesionLog: {} as SesionLog[], //log de la sesion activa en la pantalla que esta viendo el usuario
  }),

  getters: {
    getById: (state) => async (id: number): Promise<Sesion> => {
      const sesion = state.sesiones.find(sesion => sesion.id === id);
      //si no la encontramos en el store la traemos por api
      if(sesion === null || sesion === undefined)
      {
        console.log('No encontramos la sesión en el store, la traemos por api')
        return await SesionService.get(id);
      }
      else
      return sesion;
    },

    //devuelve la lista de las personas que no se anotan
    getNoseAnotan: (state) => async (sesion_id: number): Promise<any[]> => {
      const sesion = state.sesiones.find(sesion => sesion.id === sesion_id)
      if(sesion === null || sesion === undefined) return []

      if(sesion.noSeAnotan && Array.isArray(sesion.noSeAnotan) && sesion.noSeAnotan.length > 0)
        {
          console.log('Devolvemos la lista noSeAnotan guardada en el store')
          return sesion.noSeAnotan
        }
      else
        {
          //TO-DO: Hacer que se actualice la lista de noSeAnotan si ya pasaron 5 minutos desde la última vez que se actualizó
            console.log('No esta la lista de NoSeAnotan en el store, la traemos por api')
            const tmp = await SesionService.getNoSeAnotan(sesion_id)
            console.log('Guardamos la lista de NoSeAnotan en el store')
            sesion.noSeAnotan = tmp;
            return tmp
        } 

    }
  },

  actions:{
    async get()
    { 
        console.log('Solicitamos las sesiones de la semana')
        const data = await SesionService.getSesiones( moment().format('YYYY-MM-DD'), moment().add(7, 'days').format('YYYY-MM-DD'))
        this.sesiones = data.sort((a, b) => {
              return moment(a.start_at).diff(moment(b.start_at));
        })

        //filtramos solos la de categoria = Clase
        this.sesiones = this.sesiones.filter(sesion => sesion.categoria === 'Clase')

        this.sesiones.forEach(sesion => {
          sesion.lastSync = new Date();
          sesion.id = Number(sesion.id);
        });
    },

    
    //devuelve las sesiones del usuario logueado
    updateMySesiones()
    {
      const user = currentUserStore().user;
      if(user === null || user === undefined) return []
      this.mySesiones =  this.sesiones?.filter(sesion => sesion.profesor_id === user.id || sesion.coordinador_id === user.id).slice(0, 5) ?? [];
    },

    
    //actualiza en el arreglo sesiones el profesor de una sesión determinada a traves de su id y la id del nuevo profesor
    async updateProfesor(sesion_id:number,profesor_id:number)
    {
        console.log('Actualizamos el profesor en store de la sesión ' + sesion_id + ' al profesor ' + profesor_id)
        const sesion = this.sesiones.find(sesion => sesion.id === sesion_id)
        if(sesion)
            sesion.profesor_id = profesor_id
    },
    
    //actualiza en el arreglo sesiones el coordinador de una sesión determinada a traves de su id y la id del nuevo coordinador
    async updateCoordinador(sesion_id:number,coordinador_id:number)
    {
        console.log('Actualizamos el coordinador en store de la sesión ' + sesion_id + ' al profesor ' + coordinador_id)
        const sesion = this.sesiones.find(sesion => sesion.id === sesion_id)
        if(sesion)
            sesion.coordinador_id = coordinador_id
    },

    //actualiza en el arreglo sesiones los ayudantes máximos de una sesión determinada a traves de su id y los nuevos valores
    updateMaxAyudantes(sesion_id:number,max_hombres:number,max_mujeres:number){
        console.log('Actualizamos en store sesiones los ayudantes máximos de la sesión ' + sesion_id + ' a ' + max_hombres + ' hombres y ' + max_mujeres + ' mujeres')
        const sesion = this.sesiones.find(sesion => sesion.id === sesion_id)
        if(sesion)
        {
            sesion.maxChicos = Number(max_hombres)
            sesion.maxChicas = Number(max_mujeres)
        }
    },

    update(sesion_id: number, mySesion: Sesion) {
      // Encuentra el índice de la sesión que quieres actualizar
      const index = this.sesiones.findIndex((s) => Number(s.id) === sesion_id);
      mySesion.id = Number(mySesion.id); //forzamos a que sea un número


      // Si existe, utiliza $patch para actualizar el estado reactivamente
      if (index !== -1) {
        this.$patch((state) => {
          state.sesiones[index] = mySesion;
        });
      } else {
        //si no existe simplemente lo añadimos al arreglo
        console.log('Añadimos la sesion al store ya que no exsistia en el store')
        this.sesiones.push(mySesion);
      }

      //ordenamos los asistentes por asistio de modo descendente y luego por nombre
      mySesion.asistentes = mySesion.asistentes.sort((a, b) => {
        if(a.asistio === b.asistio)
          return a.alumno.nombre.localeCompare(b.alumno.nombre)
        else
          return a.asistio ? -1 : 1
      })
    },

    //actualiza en el arreglo sesiones los ayudantes de una sesión determinada a traves de su id y los nuevos valores
    updateNoSeAnotanById (sesion_id: number, noSeAnotan: []) {
      const sesion = this.sesiones.find(sesion => sesion.id === sesion_id)
      if(sesion)
        {
          console.log('Actualizamos en store sesiones la lista de noSeAnotan de la sesión ' + sesion_id)
          sesion.noSeAnotan = noSeAnotan
          sesion.lastSync_noSeAnotan = new Date();
        }
    },

    //actualiza en el arreglo sesiones los ayudantes de una sesión determinada a traves de su id y los nuevos valores
    updateAsistentesById (sesion_id: number, asistentes: Asistencia[]) {
      const sesion = this.sesiones.find(sesion => sesion.id === sesion_id)
      if(sesion)
        {
          console.log('Actualizamos en store sesiones la lista de asistentes de la sesión ' + sesion_id)
          sesion.asistentes = asistentes
          sesion.lastSync = new Date();
        }
    },



  }
});