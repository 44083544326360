import { defineStore } from 'pinia';

export const mainStore = defineStore('mainStore', {
  state: () => ({
    loading: {
      show: false,
      message: '',
    },
    isLogged: false,
    isAlertOpen: false,
    componentKey: 0, // Add the componentKey property with an initial value
  }),

  actions: {
    setLoading(loading: boolean, message='') {
      this.loading.show = loading;
      this.loading.message = message;
    },

    // En tu store (mainStore), añade una acción para actualizar la clave del componente
    updateComponentKey() {
      this.componentKey = new Date().getTime(); // Utiliza el timestamp actual como clave
      console.log("Component key updated to", this.componentKey);
    },


    setAlertOpen(isOpen) {
      this.isAlertOpen = isOpen;
      console.log("Alert open status:", this.isAlertOpen);
    }
  }


});