import axios from "axios";
import { currentUserStore } from "@/stores/currentUser";
import tools from "@/composables/tools";
import { mainStore } from "@/stores/mainStore";
import router from "@/router";
import { User } from "@/interfaces/User";
import { useProfesorStore } from "@/stores/profesorStore";
import { useCoordinadorStore } from "@/stores/coordinadorStore";

export default function userAuth() {


	async function login(u?: string, p?: string, user_id?: number) {
			mainStore().setLoading(true, "Iniciando sesión...")
			try {
				const response = await axios.get("login", {
					params: {
						email: u,
						password: p,
						user_id: user_id,
					},
				});
				localStorage.setItem("token", response.data.access_token);
				location.href = "/";
			} catch (error: any) {
				mainStore().isLogged = false;
				mainStore().setLoading(false);
				router.push({ path: "/login" });
			}
		}

	function logout(): boolean {
		tools().setLog("Cerró sesión", "info");
		console.log("Cerrando sesion");
		return true
	}

	async function checkEmailTlf(email_tlf: string): Promise<any> {
		try {
			const response = await axios.get("checkEmailTlf", {
				params: {
					email: email_tlf,
				},
			});
			return response.data;
		} catch (error) {
			tools().toast(
				"Error en el sistema de autenticación. Consulta con el administrador #145",
				"danger",
				5000
			);
		}
	}

	/*registra a una entidad que no existe como usuario del sistema*/
	async function registerFromEntidad(
		phone_email: string,
		pw: string,
		entidad_id: number
	) {
		try {
			await axios.get("registerFromEntidad", {
				params: {
					email: phone_email,
					pw: pw,
					entidad_id: entidad_id,
				},
			});
			login(phone_email, pw);
		} catch (error) {
			tools().toast(
				"No hemos podido registrarte. Consultao con el administrador #146",
				"danger",
				5000
			);
		}
	}

	/* Devuelve true o false si tiene o no acceso al recurso basandose en los permisos. Buscamos los permisos en localstorage */
    function can(permission: string): boolean {
        const user = localStorage.getItem("user");
        if (user) {
          const parsedUser = JSON.parse(user);
          try {
            const permissions = Object.values(parsedUser.permissions);
            if (!permissions.includes(permission)) return false   
            else return true
          } catch (error) {
            return false
          }

        }
        return false;
    }

    

	
	/* Devuelve true o false si tiene o no el role buscandolo en localstorage*/
	function hasRole(role: string): boolean {
        const user = localStorage.getItem("user");
        if (user) {
			try {
				const parsedUser = JSON.parse(user);
				const roles = Object.values(parsedUser.roles);
				if (!roles.includes(role)) return false;
				else return true;	
			} catch (error) {
				return false
			}

        }
        return false
	}

	

	/* devuelve true o false si hay algun usuario logeado */
	async function isLogged(): Promise<boolean> {
		const response = await axios.get('isLogged')
		return response.data
	}

	async function getPermissions(): Promise<any> {
		try {
            const response = await axios.get('permissions')
            return response.data
        } catch (error) {
            tools().toast('No se pudo cargar los permisos','danger',5000)
        }
	}

	async function getCurrentUser(): Promise<User>{
		try {
			const response = await axios.get('currentUser')
			return response.data
		} catch (error) {
			tools().toast('No se pudo cargar el usuario actual','danger',5000)
			return {} as User
		}
	
	}

	return {
		login,
		logout,
		can,
		isLogged,
		checkEmailTlf,
		registerFromEntidad,
		getPermissions,
		getCurrentUser,
		hasRole
	};
}
