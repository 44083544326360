// stores/profesores.ts

import { defineStore } from 'pinia';
import { Profesor } from '@/interfaces/Profesor';
import tools from '@/composables/tools';
import axios from 'axios';

export const useProfesorStore = defineStore('profesores', {
  state: () => ({
    profesores: [] as Profesor[],
  }),

  getters: {
     // Getter para obtener la imagen principal de un profesor por su id
     getImageById: (state) => (id: number,imageType: 'thumb'|'icon'|'hd') => {
      const profesor = state.profesores.find(profesor => profesor.id === id);
      return profesor ? tools().getImageClase(profesor.mainImg + '_' + imageType + '.jpg') : null;
    }, 

    getById: (state) => (id: number): Profesor | null => {
      const profesor = state.profesores.find(profesor => profesor.id === id);
      return profesor !== undefined ? profesor : null;
    } 
  },

  actions: {
    async updateProfesores() {
      try {
        const response = await axios.get('galidancia/profesores');
        this.profesores = response.data;
      } catch (error) {
        tools().toast('No se pudieron cargar los profesores', 'danger', 5000);
        tools().setLog('Error al cargar los profesores', 'error');
      }
    },
  }
});